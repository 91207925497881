<!--
 * @Descripttion:
 * @version:
 * @Author: 李宗哲
 * @Date: 2020-04-15 11:08:52
 * @LastEditors: 李宗哲
 * @LastEditTime: 2020-04-15 11:08:52
 -->
<template>
  <div class="concent">
    <div class="team_title">
      <div class="team-card">
        <div class="seemainpic">
          <div class="myimg">
            <img :src="user.headimg" alt style="border-radius: 1rem;" />
          </div>
          <span class="myname">{{user.empname}}</span>
          <div class="grade">
            <img src="@/assets/nh/nhwxtd-v1.png" alt class="mygrade" v-if="degreename=='V1'" />
            <img src="@/assets/nh/nhwxtd-v2.png" alt class="mygrade" v-else-if="degreename=='V2'" />
            <img src="@/assets/nh/nhwxtd-v3.png" alt class="mygrade" v-else />
          </div>
        </div>
        <div class="steps-current" style="height: 1rem;">
          <md-steps :steps="steps" :current="currentStep" transition>
            <template slot="icon" slot-scope="props">
              <div class="stepsimg">
                <img src="@/assets/nh/nhwxtd-v1.png" alt class="degreeimg" v-if="props.index=='0'" />
                <img src="@/assets/nh/nhwxtd-v2.png" alt class="degreeimg" v-if="props.index=='1'" />
                <img src="@/assets/nh/nhwxtd-v3.png" alt class="degreeimg" v-if="props.index=='2'" />
              </div>
            </template>
          </md-steps>
        </div>
        <p class="teamnum">团队人数{{List.tdnum}}人</p>
        <div class="bottom">
          <div class="kssee">
            <img src="@/assets/nh/nhwxtd-03.png" alt class="bottomimg" />
            <span class="ksck">快速查看，出单状况轻松掌控</span>
          </div>
          <div class="performance" @click="performance()">
            <span class="tdyj">团队业绩</span>
          </div>
        </div>
      </div>
      <p class="situation">整体情况</p>
    </div>
    <div style="width: 100%;height: 0.3rem;background-color: #F9F9F9;"></div>
    <div
      style="padding: 0 0.4rem 0 0.4rem;display: flex;border-bottom: 0.01rem solid rgb(240, 240, 240);"
    >
      <div
        class="tongjitop"
        style="border-right: 0.01rem solid rgb(240, 240, 240);padding: 0.4rem;"
      >
        <p class="shuzi">{{all.ryNum}}</p>
        <p class="wenzi">累计出单人数（人）</p>
      </div>
      <div class="tongjitop" style="padding: 0.4rem;">
        <p class="shuzi">{{all.mount}}</p>
        <p class="wenzi">累计出单保费（元）</p>
      </div>
    </div>
    <div style="padding: 0 0.4rem 0 0.4rem;display: flex;">
      <div
        class="tongjitop"
        style="border-right: 0.01rem solid rgb(240, 240, 240);padding: 0.4rem;"
      >
        <p class="shuzi">{{all.ddNum}}</p>
        <p class="wenzi">累计订单数量（个）</p>
      </div>
      <div class="tongjitop" style="padding: 0.4rem;">
        <p class="shuzi">{{all.tjmount}}</p>
        <p class="wenzi">累计推荐奖励（元）</p>
      </div>
    </div>
    <div style="width: 100%;height: 0.3rem;background-color: #F9F9F9;"></div>
    <div style="padding: 0.4rem;display: flex;justify-content: space-between;">
      <span class="dyqk">当月情况</span>
      <div class="nowdate">
        <span class="date">{{nowdate}}</span>
      </div>
    </div>
    <div
      style="padding: 0 0.4rem 0 0.4rem;display: flex;border-bottom: 0.01rem solid rgb(240, 240, 240);border-top: 0.01rem solid rgb(240, 240, 240);"
    >
      <div
        class="tongjitop"
        style="border-right: 0.01rem solid rgb(240, 240, 240);padding: 0.4rem;"
      >
        <p class="shuzi">{{mouth.ryNum}}</p>
        <p class="wenzi">当月出单人数（人）</p>
      </div>
      <div class="tongjitop" style="padding: 0.4rem;">
        <p class="shuzi">{{mouth.mount}}</p>
        <p class="wenzi">当月出单保费（元）</p>
      </div>
    </div>
    <div style="padding: 0 0.4rem 0 0.4rem;display: flex;">
      <div
        class="tongjitop"
        style="border-right: 0.01rem solid rgb(240, 240, 240);padding: 0.4rem;"
      >
        <p class="shuzi">{{mouth.ddNum}}</p>
        <p class="wenzi">当月订单数量（个）</p>
      </div>
      <div class="tongjitop" style="padding: 0.4rem;">
        <p class="shuzi">{{mouth.tjmount}}</p>
        <p class="wenzi">当月推荐奖励（元）</p>
      </div>
    </div>
    <div
      style="width: 100%;height: 0.3rem;background-color: #F9F9F9;  margin-bottom: 0.5rem;border-bottom: 0.01rem solid rgb(240, 240, 240);"
    ></div>
    <div class="teammembers" @click="seeteam()">
      <span class="viewteam">查看团队成员</span>
    </div>
  </div>
</template>

<script>
import { Steps } from "mand-mobile";
import { selectteam } from "@/api/agent/agent";
import { getStorage } from "@/lib/util";
export default {
  components: {
    [Steps.name]: Steps
  },
  data() {
    return {
      currentStep: 0,
      steps: [
        {
          name: ""
        },
        {
          name: ""
        },
        {
          name: ""
        }
      ],
      nowdate: "",
      user: "",
      List: "",
      degreename: "",
      all: "",
      mouth: ""
    };
  },
  created() {
    this.user = getStorage("u_s", {});
    this.getnowdate();
  },
  mounted() {
    this.getlist();
  },
  methods: {
    getnowdate() {
      let arr = new Date();
      var year = arr.getFullYear();
      var month = arr.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      this.nowdate = year + "-" + month;
    },
    seeteam() {
      this.$router.push(`/teammembers`);
    },
    performance() {
      this.$router.push(`/businesstrack`);
    },
    getlist() {
      selectteam({ empno: this.user.empno }).then(res => {
        this.List = res.data.data;
        this.degreename = res.data.data.empno.degreename;
        this.all = res.data.data.all;
        this.mouth = res.data.data.mouth;
        if (this.degreename == "V1") {
          this.currentStep = 0.5;
        }
        if (this.degreename == "V2") {
          this.currentStep = 1.5;
        }
        if (this.degreename == "V3") {
          this.currentStep = 2;
        }
      });
    }
  }
};
</script>

<style scoped>
.concent {
  background-color: #ffffff;
  height: 100vh;
  overflow: auto;
  /* padding-bottom: 1.5rem; */
}
.team_title {
  padding: 0.5rem 0.48rem 0 0.48rem;
  margin-bottom: 0.3rem;
}
.team-card {
  width: 100%;
  background-image: url("~@/assets/nh/nhwxtd-01.png");
  height: 4.6rem;
  background-size: 100% 100%;
  position: relative;
  padding-top: 0.4rem;
}
.situation {
  font-family: PingFang-SC-Bold;
  color: #3d3d3d;
  font-size: 0.44rem;
  font-weight: bold;
  margin-top: 0.5rem;
}
.seemainpic {
  width: 100%;
  padding: 0 0.4rem;
  display: flex;
  align-items: center;
}
.myimg {
  border: 0.1rem solid #e6bb9a;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 1rem;
}
.myname {
  font-family: PingFang-SC-Medium;
  color: #ffffff;
  font-size: 0.44rem;
  margin-left: 0.2rem;
}
.grade {
  width: 1.4rem;
  background-image: url("~@/assets/nh/nhwxtd-02.png");
  height: 0.5rem;
  background-size: 100% 100%;
  margin-left: 0.2rem;
}
.mygrade {
  position: relative;
  width: 0.3rem;
  height: 0.3rem;
  top: 0.08rem;
  left: 0.65rem;
}
.teamnum {
  font-family: PingFang-SC-Bold;
  color: #ffffff;
  font-weight: bold;
  padding: 0 0.4rem;
  font-size: 0.3rem;
  letter-spacing: 0.02rem;
  margin-top: 0.3rem;
}
.bottom {
  height: 1rem;
  position: absolute;
  bottom: 0rem;
  width: 100%;
  background-color: rgba(255, 211, 186, 0.3);
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  display: flex;
  justify-content: space-between;
}
.bottomimg {
  width: 0.4rem;
  height: 0.4rem;
}
.kssee {
  margin-left: 0.3rem;
  line-height: 1rem;
}
.ksck {
  color: #ffffff;
  font-family: PingFang-SC-Medium;
  font-size: 0.36rem;
  margin-left: 0.2rem;
}
.performance {
  margin-top: 0.15rem;
  margin-right: 0.4rem;
  background-color: #ffffff;
  border-radius: 1rem;
  width: 1.9rem;
  height: 0.7rem;
  text-align: center;
  line-height: 0.7rem;
}
.tdyj {
  color: #fb6821;
  font-family: PingFang-SC-Medium;
  font-size: 0.33rem;
}
.tongjitop {
  width: 50%;
  text-align: center;
}
.shuzi {
  color: #383838;
  font-family: OPPOSans-B;
  font-weight: bold;
  font-size: 0.39rem;
}
.wenzi {
  color: #989898;
  font-family: PingFang-SC-Medium;
  font-size: 0.32rem;
}
.dyqk {
  color: #3d3d3d;
  font-family: PingFang-SC-Bold;
  font-weight: bold;
  font-size: 0.46rem;
}
.nowdate {
  border: 0.02rem solid #f0f0f0;
  border-radius: 1rem;
  width: 1.8rem;
  height: 0.6rem;
  text-align: center;
}
.date {
  font-size: 0.3rem;
  color: #757575;
  font-family: PingFang-SC-Medium;
}
.teammembers {
  margin: 0 0.4rem;
  background-color: #fb6821;
  height: 1.23rem;
  text-align: center;
  line-height: 1.2rem;
  border-radius: 1rem;
}
.viewteam {
  font-size: 0.39rem;
  color: #ffffff;
  font-family: PingFang-SC-Medium;
}
.degreeimg {
  width: 0.3rem;
  height: 0.3rem;
  margin-top: 0.05rem;
}
.stepsimg {
  width: 0.6rem;
  height: 0.3rem;
  background-color: aliceblue;
  border-radius: 0.2rem;
}
/deep/ .bar-inner {
  background-color: #ffdcae !important;
  height: 0.08rem !important;
}
/deep/ .horizontal-bar {
  height: 0.08rem !important;
  background-color: #eabd9e !important;
}
</style>
